import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "portfolio",
  "title": "Stabila",
  "thumbnail": "/img/pig.gif",
  "description": "För dig som vill ta en mer stabil approach mot de största kryptovalutorna med lägre risk",
  "percentages": [{
    "percentage": 50,
    "name": "Stable coin staking"
  }, {
    "percentage": 15,
    "name": "BTC"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      